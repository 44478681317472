import React, { createContext, useState } from "react";

interface GlobalContextProps {
  introAnimationDone: boolean;
  setIntroAnimationDone: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GlobalContext = createContext<GlobalContextProps>({
  introAnimationDone: false,
  setIntroAnimationDone: () => {
    console.warn(
      "You need to initalise GlobalContext before calling setIntroAnimationDone"
    );
  },
});

const GlobalContextProvider: React.FC = ({ children }) => {
  const [introAnimationDone, setIntroAnimationDone] = useState(false);
  const value = {
    introAnimationDone,
    setIntroAnimationDone,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
